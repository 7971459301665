
.box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width:720px) {
  
  .box {
    flex-direction: column;
    align-items: start;
  }
}